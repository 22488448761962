const v1_verificaiton: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_19330_72081)'>
			<path
				d='M12.3558 2.83406C12.5077 3.20158 12.7994 3.4937 13.1667 3.64621L14.4546 4.17967C14.8221 4.33192 15.1142 4.62393 15.2664 4.99147C15.4186 5.35901 15.4186 5.77198 15.2664 6.13952L14.7333 7.42648C14.581 7.79419 14.5808 8.20757 14.7338 8.5751L15.266 9.86168C15.3414 10.0437 15.3803 10.2388 15.3803 10.4359C15.3804 10.633 15.3416 10.8281 15.2661 11.0102C15.1907 11.1922 15.0802 11.3577 14.9408 11.497C14.8014 11.6363 14.636 11.7468 14.4539 11.8222L13.1669 12.3552C12.7994 12.5072 12.5073 12.7989 12.3548 13.1662L11.8213 14.4541C11.6691 14.8216 11.3771 15.1136 11.0095 15.2658C10.642 15.4181 10.229 15.4181 9.86148 15.2658L8.57452 14.7328C8.20697 14.5809 7.79415 14.5812 7.42683 14.7336L6.13894 15.2663C5.7716 15.4182 5.35899 15.4181 4.99175 15.266C4.62451 15.1139 4.33266 14.8222 4.18031 14.455L3.64669 13.1668C3.49473 12.7993 3.20305 12.5071 2.83576 12.3546L1.54787 11.8212C1.18049 11.669 0.888568 11.3772 0.73627 11.0098C0.583973 10.6425 0.583766 10.2297 0.735696 9.86223L1.26877 8.57527C1.42064 8.20772 1.42033 7.7949 1.26791 7.42758L0.735599 6.13876C0.660127 5.95672 0.621266 5.7616 0.621234 5.56453C0.621203 5.36747 0.660002 5.17233 0.735415 4.99027C0.810828 4.80821 0.921376 4.64279 1.06074 4.50346C1.20011 4.36414 1.36557 4.25365 1.54765 4.17829L2.83462 3.64521C3.20181 3.49338 3.49376 3.20207 3.64639 2.83521L4.17985 1.54732C4.33209 1.17978 4.6241 0.887764 4.99164 0.735523C5.35918 0.583282 5.77215 0.583282 6.13969 0.735523L7.42666 1.2686C7.79421 1.42047 8.20703 1.42016 8.57435 1.26774L9.86278 0.73635C10.2303 0.584195 10.6432 0.584226 11.0106 0.736437C11.3781 0.888648 11.6701 1.18058 11.8223 1.54802L12.3559 2.83629L12.3558 2.83406Z'
				fill='#12B76A'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M11.1333 5.9034C11.2401 5.73552 11.276 5.53205 11.2328 5.33777C11.1897 5.14348 11.0711 4.97428 10.9033 4.8674C10.7354 4.76052 10.5319 4.72471 10.3376 4.76784C10.1433 4.81097 9.97414 4.92952 9.86726 5.0974L6.93026 9.7124L5.58626 8.0324C5.46201 7.87698 5.2811 7.77729 5.08335 7.75526C4.88559 7.73322 4.68718 7.79065 4.53176 7.9149C4.37634 8.03916 4.27665 8.22006 4.25462 8.41782C4.23258 8.61557 4.29001 8.81398 4.41426 8.9694L6.41426 11.4694C6.4888 11.5627 6.58453 11.6368 6.6935 11.6857C6.80246 11.7345 6.92152 11.7566 7.04075 11.7501C7.15998 11.7437 7.27595 11.7089 7.37901 11.6485C7.48207 11.5882 7.56923 11.5042 7.63326 11.4034L11.1333 5.9034Z'
				fill='white'
			/>
		</g>
		<defs>
			<clipPath id='clip0_19330_72081'>
				<rect width='16' height='16' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export default v1_verificaiton;
