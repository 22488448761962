const v1_cartIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
			<mask id='mask0_24886_262421' maskUnits='userSpaceOnUse' x='0' y='0' width='21' height='20'>
				<rect x='0.5' width='20' height='20' fill='#fff' />
			</mask>
			<g mask='url(#mask0_24886_262421)'>
				<path
					d='M6.33203 18.3346C5.8737 18.3346 5.48134 18.1714 5.15495 17.8451C4.82856 17.5187 4.66536 17.1263 4.66536 16.668C4.66536 16.2096 4.82856 15.8173 5.15495 15.4909C5.48134 15.1645 5.8737 15.0013 6.33203 15.0013C6.79036 15.0013 7.18273 15.1645 7.50911 15.4909C7.8355 15.8173 7.9987 16.2096 7.9987 16.668C7.9987 17.1263 7.8355 17.5187 7.50911 17.8451C7.18273 18.1714 6.79036 18.3346 6.33203 18.3346ZM14.6654 18.3346C14.207 18.3346 13.8147 18.1714 13.4883 17.8451C13.1619 17.5187 12.9987 17.1263 12.9987 16.668C12.9987 16.2096 13.1619 15.8173 13.4883 15.4909C13.8147 15.1645 14.207 15.0013 14.6654 15.0013C15.1237 15.0013 15.5161 15.1645 15.8424 15.4909C16.1688 15.8173 16.332 16.2096 16.332 16.668C16.332 17.1263 16.1688 17.5187 15.8424 17.8451C15.5161 18.1714 15.1237 18.3346 14.6654 18.3346ZM4.83203 3.33464H17.1237C17.4431 3.33464 17.6862 3.477 17.8529 3.76172C18.0195 4.04644 18.0265 4.33464 17.8737 4.6263L14.9154 9.95963C14.7626 10.2374 14.5577 10.4527 14.3008 10.6055C14.0438 10.7582 13.7626 10.8346 13.457 10.8346H7.2487L6.33203 12.5013H16.332V14.168H6.33203C5.70703 14.168 5.23481 13.8937 4.91536 13.3451C4.59592 12.7964 4.58203 12.2513 4.8737 11.7096L5.9987 9.66797L2.9987 3.33464H1.33203V1.66797H4.04036L4.83203 3.33464Z'
					fill='white'
				/>
			</g>
		</svg>
	);
};
export default v1_cartIcon;
