const v1_twitter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M16.4457 23L10.8963 15.0901L3.94911 23H1.01001L9.59233 13.2311L1.01001 1H8.55596L13.7862 8.45502L20.3395 1H23.2786L15.0946 10.3165L23.9917 23H16.4457ZM19.7187 20.77H17.74L5.21836 3.23H7.19735L12.2124 10.2532L13.0796 11.4719L19.7187 20.77Z'
			fill='#242E36'
		/>
	</svg>
);

export default v1_twitter;
